<template>
  <div class="intro buy_tip">
    <van-nav-bar title="购票须知" class="none_bb">
      <template #left>
        <van-icon :size="24" color="#141212" name="arrow-left" slot="left" @click="exit()" />
      </template>
    </van-nav-bar>
    <div class="b_content">
      <div class="top_phone d_f ali_c">
        <div class="flex_1">
          <img src="./img/title.png" alt="">
          <div>出行前可提前咨询景区</div>
        </div>
        <a class="phone cur_p" :href="'tel:'+scenic.telephone">
          <img src="./img/phone.png" alt="">
        </a>

      </div>
      <div class="bc_content" v-html="scenic.payNotice"></div>
    </div>
  </div>
</template>

<script>
  import { NavBar, Icon} from 'vant'
  export default {
    name: "buyTip",
    components:{
      'van-icon': Icon,
      'van-nav-bar': NavBar,
    },
    data() {
      return {
        scenicKey:'',
        apis:{
          scenic:'/seller/find',//获取notice
        },
        scenic:{},
      }
    },
    methods: {
      exit(){
        this.$router.go(-1)
      },
      loadScenic(){//获取景点详情
        const me = this;
        me.scenic = {};
        me.imgs = [];
        me.$get({
          url:me.apis.scenic,
          params: {
            sellerId: me.scenicKey,
            token: me.$global.token
          }
        }).then(rsp => {
          me.scenic = rsp;
        });
      },
    },
    created(){

      this.getShareInfo(
        this.$global.shareTitle,
        this.$global.visitUrl,
        this.$global.shareImage,
        this.$global.shareIntro,
      );
    },
    mounted() {
      const me = this;
      me.scenicKey  = me.$route.query.key;
      me.loadScenic();
    },
  }
</script>

<style scoped lang="scss">
  .buy_tip{
    font-size: 12px;
  }
  .bc_content{
    .text{
      color: #575757;
      font-size: 14px;
    }
    .title{
      font-size:16px;

      font-weight:bold;
      color:rgba(51,51,51,1);
      margin-bottom: 15px;
    }
    padding: 12px 22px;
    background-color: white;
    border-bottom: 4px solid #F7F7F7;
  }
  .top_phone{
    .phone{
      width: 30px;
      img{
        width: 100%;
      }
    }
    .flex_1{
      img{
        width: 62px;
      }
      font-size:14px;

      font-weight:400;
      color:rgba(61,211,103,1);
    }
    height: 60px;
    background:rgba(247,247,247,1);
    padding: 0 20px;
  }
  .b_content{
    padding-top: 46px;
  }
</style>
